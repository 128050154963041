import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const AboutSection = () => {
  return (
    <section id="aboutArea" className="py-5">
      <Container>
        <Row>
          <Col>
            <div className="commnHeading">
              <h2> <span>A</span> bout Tupe Helude Associates </h2>
            </div>
          </Col>
        </Row>
        <Row className="servicesmainOuter">
          <Col>
            <p> Tupehelude Associates have developed affordable houses for making people happy in the form of Baneshwar
              Paradise. shri kshetra baneshwar is a auspicious place with nature and quietness which will assist
              modernized family to have home in a calm and prodigious environment. Baneshwar Paradise is surrounded by
              Shirval industrial area and traditional Shiv Mandir giving you the feel of both modern and traditional
              culture. </p>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Link to="/about">
              <Button variant="outline-secondary" className="comman-btn mt-4 mt-sm-2 mt-md-2">
                Read More
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;

import React, { useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SectionBanner from '../components/section/SectionBanner';
import AboutSection from '../components/section/SectionAbout';
import GetInTouchSection from '../components/section/GetInTouchSection';
import WhyChooseUsSection from '../components/section/WhyChooseUsSection';
import OurProjectSection from '../components/section/OurProjectSection';
import TestimonialsSection from '../components/section/TestimonialsSection';
import ContactUsSection from '../components/section/ContactUsSection';
import ProjectSliderSection from '../components/section/ProjectSliderSection';

function Home() {
  const pageTitle = 'Home | Tupeheludeassociates';

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  const sections = [
    <SectionBanner key="section-banner" />,
    <AboutSection key="section-about" />,
    <GetInTouchSection key="section-get-in-touch" />,
    <WhyChooseUsSection key="section-why-choose-us" />,
    <OurProjectSection key="section-our-projects" />,
    <TestimonialsSection key="section-testimonials" />,
    <ContactUsSection key="section-contact-us" />,
    <ProjectSliderSection key="section-project-slider" />,
  ];

  return (
    <div>
      <Header />
      {sections}
      <Footer />
    </div>
  );
}

export default Home;

import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import the library's styles

const Testimonials = () => {
  const testimonials = [
    {
      content: "I'm very happy about Tupe Helude Associates. What I like the most about them is the Senior citizens area and the beautiful landscaped campus with a green ambiance.",
      author: "Sunil Sanas",
    },
    {
      content: "I wanted to express our appreciation for the excellent services provided by Tupe Helude Associates.",
      author: "Kashinath Mane",
    },
    {
      content: "Tupe Helude Associates believes in building world-class projects without compromising on quality standards, innovation, and timely delivery.",
      author: "Vijay Patil",
    },
    {
      content: "Tupe Helude Associates provides well-ventilated apartments and uncluttered nature space.",
      author: "Kiran Papal",
    },
    {
      content: "The Apartments are strategically constructed keeping in mind excellent connectivity of public transport.",
      author: "Subhash Chavan",
    },
    {
      content: "Tupe Baneshwar Paradise includes all the world-class amenities such as Landscaped Garden, Play Area, Lift, Car Parking, 24Hr Backup Electricity, Senior Citizen Park, Security and 24Hr Water Supply.",
      author: "Madhumati Koge",
    },


    // Add more testimonials
  ];

  return (
    <section id="Testimonials" className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="commnHeading">
              <h2><span>W</span>hat Our Clients Say</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Carousel
              autoPlay={true} // Enable auto-play
              stopOnHover={true} // Stop auto-play when hovering over the carousel
              interval={3000} // Set the interval in milliseconds (e.g., 3000ms = 3 seconds)
              infiniteLoop={true} // Enable infinite loop
              showStatus={false} // Hide status bar (optional)
              showArrows={false}
              
            >
              {testimonials.map((testimonial, index) => (
                <div className="testimonial-slide" key={index}>
                  <div className="testimonial-content">
                    <p>{testimonial.content}</p>
                    <h5><span>- {testimonial.author}</span></h5>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;

import React from 'react';
import { Link,useLocation } from 'react-router-dom'; // Import the Link component
import { ScrollToTop } from 'react-simple-scroll-up'
// import SocialIcons from './SocialIcons';
const Footer = () => {
  const location = useLocation(); // Use useLocation to get the current location

  const isLinkActive = (path) => {
    return location.pathname === path;
  };
  return (
    <><footer>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 bottomlogoOuter">
            <img src="/assets/images/tupeheludeassociates-logo.png" alt="Logo" className="img-fluid" />
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
            <div className="footerLinks">
              <ul>
                <li><Link to="/" className={isLinkActive('/') ? ' active' : ''}>Home</Link></li>
                <li><Link to="/about" className={isLinkActive('/about') ? ' active' : ''}>About Us</Link></li>
                <li><Link to="/our-project" className={isLinkActive('/our-project') ? ' active' : ''}>Our Project</Link></li>
                <li><Link to="/gallery" className={isLinkActive('/gallery') ? ' active' : ''}>Gallery</Link></li>
                <li><Link to="/contact" className={isLinkActive('/contact') ? ' active' : ''}>Contact Us</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-sm-1 col-md-2 col-lg-2 col-xl-2"></div>
        </div>
      </div>
    </footer><div className="bottomFooter">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center mt-3 mb-3">
              <p>
                <span> © 2019 Tupe Helude Associates | <span> Design by <a href="https://sailotechnosoft.com/" target="_blank" rel="noopener noreferrer"> Sailo Technosoft </a></span> </span>
              </p>
            </div>
          </div>
        </div>
      </div><ScrollToTop smooth /></>
  );
};

export default Footer;

import React, { useState, useEffect } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa'; // Import the desired icon
import { Link } from 'react-scroll';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css'; // Import the lightbox CSS

function Overlay() {
    return (
        <section className="inner-page-projectarea">
            <div className="overlay">
                <div className="container"></div>
            </div>
            <section id="section01" className="demo">
                <h1><Link to="section-linemove-1" smooth={true} duration={500}><span></span></Link></h1>
            </section>
        </section>
    );
}

function InternalArea() {
    return (
        <section className="internalArea pt-5 pb-5 text-center">
            <div className="container">
                <div className="row mt-2">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <h2 className="mb-4 mt-1 projectHead"> Baneshwar Paradise </h2>
                        <p className="mb-2">
                            <strong><FaMapMarkerAlt className="locationMap" /></strong> Sr. No. 210, Shop No. 01,
                            Avdhoot Heights Gurudatta Housing Society, New D.P. Road, Pune: 411028.
                        </p>
                        <a href="/assets/pdf/brochure.pdf" target="_blank" className="btn btn-link btn-download mt-3" download>
                            Download Brochure
                            <i className="fa fa-download"></i>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

function Overview() {
    return (
        <section id="section-linemove-1" className="content-current pt-5 pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                        <h2 className="headtext"> Overview </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                        <p>
                            Tupehelude Associates have developed affordable houses for making people happy in the form of Baneshwar Paradise. shri kshetra baneshwar is a auspicious place with nature and quietness which will assist modernized family to have home in a calm and prodigious environment. Baneshwar Paradise is surrounded by Shirval industrial area and traditional Shiv Mandir giving you the feel of both modern and traditional culture. baneshwar Paradise is connected to Pune with 6 lane road which makes to reach home within 20 minute from Pune. This will help families to visit their daily activities such as schools, colleges , banks , hospitals and shopping malls in no time. Tupehelude Associates always tries to keep customer needs first.


                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}


function Amenities() {
    return (
        <section id="section-linemove-2" className="pt-5 pb-5">
            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                            <h2 className="headtext"> Amenities </h2>
                        </div>
                    </div>

                    {/* Row 1 */}
                    <div className="row pb-3 pt-3 aminitisicon">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 orange">
                            <div className="aminiWrap">
                                <img src="/assets/images/projects/kitchen-amenity.jpg" className="mb-4 mb-md-4 mb-sm-5" alt="Kitchen Amenity" />
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <p><span> Kitchen </span> Granite Kitchen Platform with Stainless Steel Sink. Tiles dado up to Lintel level... Exhaust fan. </p>
                        </div>
                    </div>

                    {/* Row 2 */}
                    <div className="row pb-3 aminitisicon">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <p><span> Structures </span> Earthquake resistant RCC frame structure </p>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="aminiWrap">
                                <img src="/assets/images/projects/structure-amenity.jpg" className="mb-4 mb-md-4 mb-sm-5" alt="Structure Amenity" />
                            </div>
                        </div>
                    </div>

                    {/* Row 3 */}
                    <div className="row pb-3 aminitisicon">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 orange">
                            <div className="aminiWrap">
                                <img src="/assets/images/projects/masonary-amenity.jpg" className="mb-4 mb-md-4 mb-sm-5" alt="Masonry Amenity" />
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <p><span> Masonry </span> 6"/4" red brick for external & Internal walls. smooth finished internal plasters & sand faced external plaster </p>
                        </div>
                    </div>

                    {/* Row 4 */}
                    <div className="row pb-3 aminitisicon">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <p><span> Flooring </span> Vitrified Flooring - 2'0" * 2'0" with skirting </p>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="aminiWrap">
                                <img src="/assets/images/projects/flooring-amenity.jpg" className="mb-4 mb-md-4 mb-sm-5" alt="Flooring Amenity" />
                            </div>
                        </div>
                    </div>

                    {/* Row 5 */}
                    <div className="row pb-3 aminitisicon">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 orange">
                            <div className="aminiWrap">
                                <img src="/assets/images/projects/terrace-amenity.jpg" className="mb-4 mb-md-4 mb-sm-5 " alt="Terrace Amenity" />
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <p><span> Terrace </span> Anti-skid bathroom & terrace ceramic tiles flooring</p>
                        </div>
                    </div>

                    {/* Row 6 */}
                    <div className="row pb-3 aminitisicon">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <p><span> Windows </span> Powder coated 3 track Aluminium windows with mosquito net. M.S Safety grill with Marble Sill </p>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="aminiWrap">
                                <img src="/assets/images/projects/window-amenity.jpg" className="mb-4 mb-md-4 mb-sm-5" alt="Window Amenity" />
                            </div>
                        </div>
                    </div>
                    {/* Row 7 */}
                    <div className="row pb-3 aminitisicon">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 orange">
                            <div className="aminiWrap">
                                <img src="/assets/images/projects/door-amenity.jpg" className="mb-4 mb-md-4 mb-sm-5" alt="Door Amenity" />
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <p><span> Doors </span> Decorative main entrance door with safety lock & name plate. Internal flush doors with cylindrical lock. Granite door frames for toilets & bathrooms. </p>
                        </div>
                    </div>
                    {/* Row 8 */}
                    <div className="row pb-3 aminitisicon">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <p><span> Painting </span> External cement paint. Internal oil bond distember. </p>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="aminiWrap">
                                <img src="/assets/images/projects/painting-amenity.jpg" className="mb-4 mb-md-4 mb-sm-5" alt="Plumbing Amenity" />
                            </div>
                        </div>
                    </div>
                    {/* Row 9 */}
                    <div className="row pb-3 aminitisicon">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 orange">
                            <div className="aminiWrap">
                                <img src="/assets/images/projects/electric-amenity.jpg" className="mb-4 mb-md-4 mb-sm-5" alt="Electrical Amenity" />
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <p><span> Electrical </span> Concealed copper wiring with modular switches. Adequate electrical points in all rooms. T.V. & Telephone points in living room & master bedroom. </p>
                        </div>
                    </div>

                    {/* Row 10 */}
                    <div className="row pb-3 aminitisicon">

                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <p><span> Toilets </span> Designer glazed tiles dado up to lintel level. Anti-skid ceramic tiles flooring. Standard quality C.P. fittings & sanitary ware. </p>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 orange">
                            <div className="aminiWrap">
                                <img src="/assets/images/projects/toilet-amenity.jpg" className="mb-4 mb-md-4 mb-sm-5" alt="Toilet Amenity" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

function Specifications() {
    return (
        <section id="section-linemove-3" className="bg-1 text-center about-us1">
            <div className="about-us pt-5 pb-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h2 className="headtext"> Specifications </h2>
                        </div>
                    </div>
                    <div className="row">
                        {specificationsData.map((item, index) => (
                            <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div className="wrapdiv mt-3">
                                    <img src={require(`../assets/images/icon/${item.icon}`)} alt={`${item.title} Icon`} />
                                    <h4>{item.title}</h4>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

const specificationsData = [
    { icon: 'stairs.png', title: '2 Lifts & 2 Stair-case for each wing' },
    { icon: 'fire-fighting.png', title: 'Fire fighting stair-case' },
    { icon: 'rainwater-harvest.png', title: 'Rainwater harvesting' },
    { icon: 'solar-panel.png', title: 'Solar water heating system' },
    { icon: 'global-water.png', title: 'Sewage Water treatment plant.' },
    { icon: 'domestic-water.png', title: 'Domestic Water treatment plant' },
    { icon: 'gate.png', title: 'Attractive entrance gate with security cabin' },
    { icon: 'parking.png', title: 'Adequate reserved & visitors parking' },
    { icon: 'hotel-elevator-sign.png', title: 'Provision of lifts with generator back up' },
    { icon: 'generator.png', title: 'Generator Backup for lighting in common areas' },
    { icon: 'sign.png', title: 'Internal roads with street lighting & street furniture' },
    { icon: 'ecology.png', title: 'Beautiful Landscaped campus with green ambiance' },
    { icon: 'population-system.png', title: 'Senior citizens area' },
    { icon: 'playground.png', title: 'Children\'s play Area' },
    { icon: 'compound-wall.png', title: 'Compound wall with grill & decorative entrance gate' },
    { icon: 'club.png', title: 'Club House with Indoor games' },
    { icon: 'temple.png', title: 'Temple' },
];



function FloorPlans() {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxIndex, setLightboxIndex] = useState(0);

    const openLightbox = (index) => {
        setLightboxIndex(index);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setLightboxOpen(false);
    };

    return (
        <section id="section-linemove-4" className="pt-5 pb-5">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 text-center">
                        <h2 className="headtext"> Floor Plans </h2>
                    </div>
                </div>
                <div className="row justify-content-center">
                    {floorPlanData.map((item, index) => (
                        <div key={index} className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-2">
                            <div className="floorwrapper">
                                <button onClick={() => openLightbox(index)}>
                                    <img src={item.imageLink} alt={item.altText} className="img-fluid" />
                                </button>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {lightboxOpen && (
                <Lightbox
                    mainSrc={floorPlanData[lightboxIndex].imageLink}
                    onCloseRequest={closeLightbox}
                    imageTitle={floorPlanData[lightboxIndex].description}
                />
            )}
        </section>
    );
}

const floorPlanData = [
    {
        imageLink: '/assets/images/floor-plan/typical-floor-plan-odd.jpg',
        altText: 'Typical floor plan 1st, 3rd, 5th & 7th floor wing B & C',
        description: 'Typical floor plan 1st, 3rd, 5th & 7th floor wing B & C',
    },
    {
        imageLink: '/assets/images/floor-plan/typical-floor-plan-even.jpg',
        altText: 'Typical floor plan 2nd, 4th & 6th floor Wing B & C',
        description: 'Typical floor plan 2nd, 4th & 6th floor Wing B & C',
    },
];


const LocationMap = () => (
  <section id="section-linemove-5" className="pt-5">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 text-center">
          <h2 className="headtext"> Location Map </h2>
        </div>
      </div>
    </div>

    <iframe title="Location Map" 
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.502244262678!2d73.93916651530806!3d18.506192474442845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c21c7a172e85%3A0x4ba3ac079cce13dc!2sAvdhoot+Heights%2C+Survey+No+210%2CNew+D+P+Road%2C+Baburao+Shankarrao+Tupe+Rd%2C+Malwadi%2C+Hadapsar%2C+Pune%2C+Maharashtra+411028!5e0!3m2!1sen!2sin!4v1554206787376!5m2!1sen!2sin"
      width="100%" 
      height="350" 
      frameBorder="0" 
      style={{ border: '0' }} 
      allowFullScreen
    ></iframe>
  </section>
);


function ProjectDetails() {
    const [pageTitle] = useState('Baneshwar Paradise | Tupeheludeassociates'); // Initial title

    useEffect(() => {
        document.title = pageTitle; // Update the document's title on every pageTitle change
    }, [pageTitle]);

    return (
        <div>
            <Header />
            <section className="inner-page-projectarea">
                <Overlay />
            </section>
            <InternalArea />
            <Overview />
            <Amenities />
            <Specifications />
            <FloorPlans />
            <LocationMap />            
            <Footer />
        </div>
    );
}

export default ProjectDetails;

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaPhoneVolume } from 'react-icons/fa';

const GetInTouchSection = () => {
  return (
    <section id="bookapointmentSection" className="mt-2">
      {/* <Container fluid> */}
        <Row>
          <Col className="at-Call-left">
            <div className="at-inside-Call">
              <h5>GET IN TOUCH WITH US,</h5>
              <h2>BOOK YOUR HOUSE NOW</h2>
            </div>
          </Col>
          <Col className="at-Call-right">
            <div className="at-Call-right-inside">
              <h2 className="display-4">
                <FaPhoneVolume className="pr-0 pr-xl-4 pr-md-4 pr-sm-4 mb-4 mb-sm-0 mb-md-0" /> +91 8975909192/9822178217
              </h2>
            </div>
          </Col>
        </Row>
      {/* // </Container> */}
    </section>
  );
};

export default GetInTouchSection;

import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
function Contact() {
  const [pageTitle] = useState('Contact Us | Tupeheludeassociates'); // Initial title

  useEffect(() => {
      document.title = pageTitle; // Update the document's title on every pageTitle change
  }, [pageTitle]);
  return (
    <>
        <Header />
      <section className="inner-page-banner-area">
        <div className="overlay">
          <div className="container">
            <div className="breadcrumbs-area">
              <h1> Contact Us </h1>
            </div>
          </div>
        </div>
      </section>

      <section className="internalArea pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <h3 className="mb-3 mt-2 mt-md-4 mb-md-5"> Tupe-Helude Associates </h3>
            </div>
          </div>

          <div className="row mt-3" id="">
            <div className="col-md-6 col-sm-12 col-xs-12 pr-3 pr-md-0">
              <div className="addressBox mb-5">
                <h2 className="mt-4 mb-2"> Mr. Aniket Namdeo Helude </h2>

                <h4 className="mb-3 mt-4"><i className="fas fa-map-marker-alt pr-2"></i> Address </h4>
                <p> <span> Pune Office: </span> 
                  S. No.210, Shop No. 01, <br />
                  Avdhoot Heights Gurudatta Housing Society, <br />
                  New D.P. Road, Pune - 411028. </p>
                <p> <span> Site Office: </span> 
                  “BANESHWAR PARADISE”, Gat No.262/1, <br />
                  Cheladi Nasarapur, Nasarapur, <br />
                  Tal-Bhor, Nasarapur, Pune - 412213. </p>

                <h4 className="mb-2 mt-4"><i className="fas fa-phone-square pr-2"></i> Phone </h4>
                <p className="mb-2"><a href="tel:+918975909192"> +91-8975909192/9822178217 </a></p>

                <h4 className="mb-2 mt-4"><i className="fas fa-envelope pr-2"></i> Email </h4>
                <p className="mb-2"><a href="mailto:thassociate2010@gmail.com"> thassociate2010@gmail.com </a></p>
              </div>
            </div>

            <div className="col-md-6 col-sm-12 col-xs-12 pl-3 pl-md-0">
              <form
                className="form-horizontal formStyle"
                method="post"
                action="#"
                id="seeDemoForm"
              >
                <div className="validateContainer">
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="mb-4 mt-0">Enquire Now</h4>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="validateField">
                          <input
                            type="text"
                            className="form-control validateRequired validateAlphaonly"
                            name="name"
                            id="name"
                            placeholder="Enter Name"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="validateField">
                          <input
                            type="text"
                            className="form-control validateRequired validateEmail"
                            name="email"
                            id="email"
                            placeholder="Enter Email ID"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="validateField">
                          <input
                            id="phone"
                            name="phone"
                            className="form-control validateRequired validateNumber validateMobileNoLimit"
                            minLength="6"
                            maxLength="12"
                            placeholder="Enter Phone No."
                            type="text"
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="validateField">
                          <textarea
                            className="form-control"
                            id="contact-message"
                            name="message"
                            rows="4"
                            placeholder="Write a Message here..."
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <input
                        type="submit"
                        className="btn btn-default checkValidationBtn submitBtn"
                        value="Submit"
                      />
                      <input
                        type="reset"
                        className="btn btn-default resetBtn"
                        value="Reset"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section className="mapOuterArea">
        <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.502244262678!2d73.93916651530806!3d18.506192474442845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c21c7a172e85%3A0x4ba3ac079cce13dc!2sAvdhoot+Heights%2C+Survey+No+210%2CNew+D+P+Road%2C+Baburao+Shankarrao+Tupe+Rd%2C+Malwadi%2C+Hadapsar%2C+Pune%2C+Maharashtra+411028!5e0!3m2!1sen!2sin!4v1554206787376!5m2!1sen!2sin"
          width="100%"
          height="350"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen=""
        ></iframe>
      </section>
      <Footer />
    </>
  );
}

export default Contact;

import React, { useState,useEffect} from 'react';
import ImageGallery from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const GalleryItem = ({ image, index, openLightbox }) => (
  <div className="col-md-4 col-sm-6 col-12 mb-5">
    <button onClick={() => openLightbox(index)}>
      <img src={image} className="img-fluid" alt={`Gallery ${index + 1}`} />
    </button>
  </div>
);

const Gallery = () => {
  const [pageTitle] = useState('Gallery | Tupeheludeassociates'); // Initial title

  useEffect(() => {
      document.title = pageTitle; // Update the document's title on every pageTitle change
  }, [pageTitle]);

  const images = [
    '/assets/images/gallery/gallery-1.JPG',
    '/assets/images/gallery/gallery-2.JPG',
    '/assets/images/gallery/gallery-1sm.jpg',
    '/assets/images/gallery/gallery-4.jpg',
    '/assets/images/gallery/gallery-5.jpg',
    '/assets/images/gallery/gallery-6.jpg',
    '/assets/images/gallery/gallery-7.jpg',
    '/assets/images/gallery/gallery-8.jpg',
    '/assets/images/gallery/gallery-9.jpg',
    '/assets/images/gallery/gallery-10.jpg',
    '/assets/images/gallery/gallery-11.jpg',
    '/assets/images/gallery/gallery-12.jpg',
    '/assets/images/gallery/gallery-13.jpg',
    '/assets/images/gallery/gallery-14.jpg',
    '/assets/images/gallery/gallery-15.JPG',
    '/assets/images/gallery/gallery-16.JPG',
    '/assets/images/gallery/gallery-17.JPG',
    '/assets/images/gallery/gallery-18.JPG',
  ];

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setPhotoIndex(0);
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      <Header />
      <div>
        <section className="inner-page-banner-area">
          {/* ... (your banner code) */}
        </section>
        <section className="internalArea pt-5 pb-5">
          <div className="container">
            <div className="row galleryWrapper text-center">
              {images.map((image, index) => (
                <GalleryItem
                  key={index}
                  image={image}
                  index={index}
                  openLightbox={openLightbox}
                />
              ))}
            </div>
          </div>
        </section>
        {isOpen && (
          <ImageGallery
            startIndex={photoIndex}
            items={images.map((image) => ({ src: image }))}
            onCloseRequest={closeLightbox}
            mainSrc={images[photoIndex]}
          />
        )}
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Gallery;

import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

// const ManagementTeamMember = ({ name, designation }) => (
//   <div className="col-12 col-sm-6 col-md-4 col-xl-4">
//     <div className="imgOuter mt-xl-3 mb-xl-5 mb-sm-3 mt-sm-3 mb-3 mt-3">
//       <img src="/assets/images/man-placeholder.png" alt="management" className="img-fluid" />
//       <div className="overlay">
//         <div className="text">{name} ({designation})</div>
//       </div>
//     </div>
//   </div>
// );

function About() {
  const [pageTitle] = useState('About Us | Tupeheludeassociates'); // Initial title

  useEffect(() => {
      document.title = pageTitle; // Update the document's title on every pageTitle change
  }, [pageTitle]);


  // const managementTeam = [
  //   { name: "Mr. xyz", designation: "Sr. Manager" },
  //   { name: "Mr. abc", designation: "Manager" },
  //   { name: "Mr. def", designation: "Associate" },
  // ];

  return (
    <>
      <Header />
      <section className="inner-page-banner-area">
        {/* ... (your banner code) */}
      </section>
      <section className="internalArea pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <span className="tagline mb-4">
                In our work we have pride, quality is what we provide.
              </span>
              {/* ... (your content) */}
              <p>
                Tupehelude Associates have developed affordable houses for making
                people happy in the form of Baneshwar Paradise. shri kshetra
                baneshwar is a auspicious place with nature and quietness which
                will assist modernized family to have home in a calm and prodigious
                environment. Baneshwar Paradise is surrounded by Shirval industrial
                area and traditional Shiv Mandir giving you the feel of both modern
                and traditional culture. Baneshwar Paradise is connected to Pune
                with 6 lane road which makes to reach home within 20 minute from
                Pune. This will help families to visit their daily activities such
                as schools, colleges , banks , hospitals and shopping malls in no
                time. Tupehelude Associates always tries to keep customer needs first.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <h3 className="mb-5 mt-2"> Vision &amp; Mission </h3>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
              {/* ... (your vision content) */}
              <div className="visionOuter px-4 py-4 mb-4 ">
                <h2 className="mb-4"> Vision </h2>
                <img
                  src="/assets/images/icon/vision.png"
                  title="vision"
                  alt="vision"
                  className="mb-4"
                />
                <p>
                  To Infuse The Most Ideal Locales With The Best Quality
                  Residential Havens And Commercial Structures , And Develop All
                  Projects With Utmost Responsibility And Sustainability.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
              {/* ... (your mission content) */}
              <div className="missionOuter px-4 py-4 ">
                <h2 className="mb-4"> Mission </h2>
                <img
                  src="/assets/images/icon/mission.png"
                  title="vision"
                  alt="vision"
                  className="mb-4"
                />
                <p>
                  To be Amongst The Leading Real Estate Development Companies By
                  Capitalising On The Experience , Instilling Projects With Morden
                  Technology And Innovation, And Emerging As The First Choice Of
                  Residential And Commercial Space Buyers.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-xl-12">
              <h3 className="mb-5 mt-4"> Management Team </h3>
            </div>
            {managementTeam.map((member, index) => (
              <ManagementTeamMember
                key={index}
                name={member.name}
                designation={member.designation}
              />
            ))}
          </div> */}
        </div>
      </section>
      <Footer />
    </>
  );
}

export default About;

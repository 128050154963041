import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaMapMarkerAlt, FaPhoneSquare, FaEnvelope } from 'react-icons/fa';

const ContactUsSection = () => {
  return (
    <section id="projects" className="py-5">
      <Container>
        <Row>
          <Col>
            <div className="commnHeading">
              <h2><span> G</span> et in Touch</h2>
            </div>
          </Col>
        </Row>
        <Row className="OuterWrapper text-center pb-5">
          <Col>
            <div className="innerWrapper">
              <Row>
                <Col xl={4} md={4} sm={12} xs={12}>
                  <div className="c_box_item">
                    <h4 className="pb-2"> <FaMapMarkerAlt className="pr-2" /> Address </h4>
                    <p className="mb-4"> Sr. No. 210, Shop No. 01,
                      Avdhoot Heights Gurudatta Housing Society,
                      New D.P. Road, Pune: 411028. </p>
                  </div>
                </Col>
                <Col xl={4} md={4} sm={12} xs={12}>
                  <div className="c_box_item">
                    <h4 className="pb-2"> <FaPhoneSquare className="pr-2" /> Phone </h4>
                    <p className="mb-2"> <a href="tel:+918975909192"> +91 8975909192/9822178217 </a></p>
                  </div>
                </Col>
                <Col xl={4} md={4} sm={12} xs={12}>
                  <div className="c_box_item">
                    <h4 className="pb-2"><FaEnvelope className="pr-2" /> Email </h4>
                    <p className="mb-2"><a href="mailto:thassociate2010@gmail.com">thassociate2010@gmail.com</a></p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactUsSection;

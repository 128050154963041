import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation
import { FaPhoneSquare, FaEnvelope, FaBars } from 'react-icons/fa'; // Import Font Awesome icons
import { Container, Navbar, Nav, Collapse } from 'react-bootstrap'; // Import React Bootstrap components
import { RotatingTriangles } from 'react-loader-spinner'; // Import the loader component
import { useState, useEffect } from 'react'; // Import the useEffect hook

import '../assets/css/style.css';
const Header = () => {
  const [isOpen, setIsOpen] = React.useState(true);
  const location = useLocation(); // Use useLocation to get the current location
  const [loading, setLoading] = useState(false); // State for tracking loading

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };
  const isLinkActive = (path) => {
    return location.pathname === path;
  };

  useEffect(() => {
    // Simulate loading effect
    const loadingTimer = setTimeout(() => {
      setLoading(false);
    }, 1500); // Adjust the timeout as needed

    // Clean up the timer when component unmounts
    return () => clearTimeout(loadingTimer);
  }, []);
  return (
    <>
      {loading ? ( // Show loader while loading
        <div className="loader-overlay">
          <RotatingTriangles
            visible={true}
            height={80}
            width={80}
            ariaLabel="rotating-triangles-loading"
            wrapperStyle={{}}
            wrapperClass="rotating-triangles-wrapper"
          />
        </div>
      ) : (
        <><Container id="topNav">
            <Navbar expand="lg" className="pl-0 pr-0">
              <Link to="/" className="navbar-brand mb-xl-0 mb-lg-0 mb-md-2">
                <img
                  src="/assets/images/tupeheludeassociates-logo.png"
                  alt="Tupeheludeassociates"
                  title="Tupeheludeassociates" />
              </Link>
              <Navbar.Toggle
                aria-controls="navbarNavDropdown"
                onClick={toggleNav}
              >
                <FaBars />
              </Navbar.Toggle>
              <Collapse id="navbarNavDropdown" in={true}>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="#" className="phonenumber">
                    <FaPhoneSquare className="pr-1 pl-sm-3 pl-3 pl-md-3 pl-xl-1" /> +91 8975909192/9822178217
                  </Nav.Link>
                  <Nav.Link href="mailto:thassociate2010@gmail.com">
                    <FaEnvelope className="pr-1 pl-sm-3 pl-3 pl-md-3 pl-xl-1" /> thassociate2010@gmail.com
                  </Nav.Link>
                </Nav>
              </Collapse>
            </Navbar>
          </Container><div id="bottomNav">
              <Container>
                <Navbar expand="lg">
                  <Navbar.Toggle aria-controls="navbarSupportedContent" />
                  <Collapse id="navbarSupportedContent" in={true}>
                    <Nav className="m-auto">
                      <Nav.Item>
                        <Link
                          to="/"
                          className={isLinkActive('/') ? 'nav-link active' : 'nav-link'}
                        >
                          Home
                        </Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Link
                          to="/about"
                          className={isLinkActive('/about') ? 'nav-link active' : 'nav-link'}
                        >
                          About Us
                        </Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Link
                          to="/our-project"
                          className={isLinkActive('/our-project') ? 'nav-link active' : 'nav-link'}
                        >
                          Our Project
                        </Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Link
                          to="/gallery"
                          className={isLinkActive('/gallery') ? 'nav-link active' : 'nav-link'}
                        >
                          Gallery
                        </Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Link
                          to="/contact"
                          className={isLinkActive('/contact') ? 'nav-link active' : 'nav-link'}
                        >
                          Contact Us
                        </Link>
                      </Nav.Item>
                    </Nav>
                  </Collapse>
                </Navbar>

              </Container>
            </div></>
      )}
    </>
  );

};

export default Header;

import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link} from 'react-router-dom'; // Import useLocation

const OurProjectSection = () => {
  return (
    <section id="services" className="py-5">
      <Container>
        <Row>
          <Col>
            <div className="commnHeading">
              <h2 className="display-3"><span>O</span> ur Project</h2>
            </div>
          </Col>
        </Row>
        <Row className="main">
          <Col xl={7} lg={7} md={7} sm={12} xs={12} className="image-element align-self-stretch pl-0 pr-0 wow fadeInLeft animated" data-wow-delay="0.4s">
            <div className="img-wrap">
              <img src="/assets/images/baneshwar-paradise-bg.jpg" alt="Baneshwar Paradise" />
            </div>
          </Col>
          <Col xl={5} lg={5} md={5} sm={12} xs={12} className="text-element pl-0 pr-0">
            <div className="text-content">
              <h2 className="mbr-title mbr-fonts-style align-center">
                Baneshwar Paradise
              </h2>
              <div className="underline align-center">
                <div className="line"></div>
              </div>
              <div className="mbr-section-text">
                <p className="mbr-text pt-2 mbr-light mbr-fonts-style align-center display-5">
                  Developed affordable houses for making people happy in the form of Baneshwar Paradise
                </p>
              </div>
              <div className="mbr-section-btn align-center">
                <Link to="/our-project">
                  <Button variant="outline-secondary" className="comman-btn">
                    Read More
                  </Button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default OurProjectSection;

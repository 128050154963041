import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const WhyChooseUsSection = () => {
  return (
    <section className="service-area py-5" id="service">
      <Container>
        <Row className="d-flex justify-content-center">
          <Col md={8} className="pb-40 header-text">
            <div className="commnHeading">
              <h2 className="display-3"><span>W</span> hy Choose US</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={6} className="pb-30 wow fadeInUp animated" data-wow-delay="0.2s">
            <div className="single-service mb-4 mb-md-4 mb-sm-4 mb-xl-5">
              <h4> Creativity </h4>
              <p>
                We are turning new and imaginative ideas into reality, It is more than just enthusiasm or excitement.
              </p>
            </div>
          </Col>
          <Col lg={4} md={6} className="pb-30 wow fadeInUp animated" data-wow-delay="0.3s">
            <div className="single-service mb-4 mb-md-4 mb-sm-4">
              <h4> Passion </h4>
              <p>
                Our promise of quality, our pledge for a secure future and commitment for timely possession makes us what
                we are today.
              </p>
            </div>
          </Col>
          <Col lg={4} md={6} className="pb-30 wow fadeInUp animated" data-wow-delay="0.4s">
            <div className="single-service mb-4 mb-md-4 mb-sm-4">
              <h4> Consistency </h4>
              <p>
                We are having greater consistency towards our work.
              </p>
            </div>
          </Col>
          <Col lg={4} md={6} className="wow fadeInUp animated" data-wow-delay="0.5s">
            <div className="single-service mb-4 mb-md-4 mb-sm-4">
              <h4> Honest &amp; Dependable </h4>
              <p>
                We rarely miss a deadline when it comes to delivering projects.
              </p>
            </div>
          </Col>
          <Col lg={4} md={6} className="wow fadeInUp animated" data-wow-delay="0.6s">
            <div className="single-service mb-4 mb-md-4 mb-sm-4">
              <h4> We Create Lifestyle </h4>
              <p>
                For us, what matters most is providing a lifestyle that grants comfort in every space we create.
              </p>
            </div>
          </Col>
          <Col lg={4} md={6} className="wow fadeInUp animated" data-wow-delay="0.7s">
            <div className="single-service mb-4 mb-md-4 mb-sm-4">
              <h4> Positive Reviews </h4>
              <p>
                We are happy to hear that you got exactly what you wanted from us.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WhyChooseUsSection;

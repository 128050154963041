import React from 'react';
import { Carousel } from 'react-bootstrap';

const SectionBanner = () => {
  return (
    <section className="banner">
      {/* <Carousel id="carouselExampleCaptions" interval={5000} nextIcon={<FaLongArrowAltRight />} prevIcon={<FaLongArrowAltLeft />}> */}
      <Carousel id="carouselExampleCaptions" interval={5000} >
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/assets/images/banner-01.jpg"
            alt="Tupe Helude Associates"
            title="Tupe Helude Associates"
          />
          <Carousel.Caption className="d-none d-md-block">
            <h2 className="mb-2"> Baneshwar Paradise </h2>
            <p> Developed affordable houses for making people happy in the form of Baneshwar Paradise </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/assets/images/banner-02.jpg"
            alt="Tupe Helude Associates"
            title="Tupe Helude Associates"
          />
          <Carousel.Caption className="d-none d-md-block">
            <h2 className="mb-2"> Shri Kshetra Baneshwar </h2>
            <p>Auspicious place with nature and quietness </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/assets/images/banner-03.jpg"
            alt="Tupe Helude Associates"
            title="Tupe Helude Associates"
          />
          <Carousel.Caption className="d-none d-md-block">
            <h2 className="mb-2"> New Building Wing - D </h2>
            <p> New Building Wing - D is Under Construction </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      {/* <div className="carousel-control-container">
        <a className="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
          <FaLongArrowAltLeft />
          <span className="sr-only"></span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
          <FaLongArrowAltRight />
          <span className="sr-only"></span>
        </a>
      </div> */}
    </section>
  );
};

export default SectionBanner;

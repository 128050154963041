import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'jquery-simplyscroll';
import '../../assets/css/simplyscroll.min.css';
import $ from 'jquery';

const ProjectSliderSection = () => {
  useEffect(() => {
    // Initialize the simplyScroll plugin
    $('#career-scroller').simplyScroll({
      // Configuration options here
    });
  }, []);

  const projectItems = [
    { src: '/assets/images/projects/marque-1.jpg', description: 'Side view of site' },
    { src: '/assets/images/projects/marque-3.jpg', description: 'Front view of site' },
    { src: '/assets/images/projects/marque-2.jpg', description: 'View inside the room' },
    { src: '/assets/images/projects/marque-4.jpg', description: 'Senior citizens area' },
    { src: '/assets/images/projects/marque-5.jpg', description: 'Fire fighting stair-case' },
    { src: '/assets/images/projects/marque-6.jpg', description: '2 Lifts & Stair-case for each wing' },
    { src: '/assets/images/projects/marque-1.jpg', description: 'Side view of site' },
    { src: '/assets/images/projects/marque-3.jpg', description: 'Front view of site' },
    { src: '/assets/images/projects/marque-2.jpg', description: 'Childrens play area' },
    // Add more project items here
  ];
  

  return (
    <section className="scroller-wrapper pl-0 pr-0">
      <ul id="career-scroller" className="simply-scroll-list">
        {projectItems.map((item, index) => (
          <li key={index}>
            <span>
              <img src={item.src} alt={item.description} />
              <Link  href="">
                <div className="overlay">
                  <p>{item.description}</p>
                </div>
              </Link>
            </span>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default ProjectSliderSection;
